import {
  DateTimeRangePicker,
  DateTimeRangePickerProps,
} from '@fluidtruck/core';
import { tz } from 'moment';
import React from 'react';
import type { FieldValues, UseControllerOptions } from 'react-hook-form';
import { useController } from 'react-hook-form';

type Props<T extends FieldValues> = Omit<UseControllerOptions<T>, 'name'> &
  Pick<
    DateTimeRangePickerProps,
    | 'displayFormat'
    | 'labels'
    | 'reservations'
    | 'maxHours'
    | 'displayTime'
    | 'openDirection'
    | 'disabled'
    | 'rootContainerSx'
  > & {
    names?: {
      startDate: UseControllerOptions['name'];
      endDate: UseControllerOptions['name'];
      startTime: UseControllerOptions['name'];
      endTime: UseControllerOptions['name'];
    };
    onStartDateChangeCb?: DateTimeRangePickerProps['onStartDateChange'];
    onStartTimeChangeCb?: DateTimeRangePickerProps['onStartTimeChange'];
    onEndDateChangeCb?: DateTimeRangePickerProps['onEndDateChange'];
    onEndTimeChangeCb?: DateTimeRangePickerProps['onEndTimeChange'];
    timezoneAbbr?: string;
  };

export const DTRPController = <T extends FieldValues>({
  control,
  names,
  displayFormat,
  onStartDateChangeCb,
  onStartTimeChangeCb,
  onEndDateChangeCb,
  onEndTimeChangeCb,
  timezoneAbbr = tz(tz.guess()).zoneAbbr(),
  ...props
}: Props<T>) => {
  if (!control) {
    throw new Error('RHF control and name must be provided');
  }

  const { field: startDateField } = useController<T>({
    name: (names?.startDate || 'pickupDate') as any,
    control,
    rules: { required: true },
  });
  const { field: endDateField } = useController<T>({
    name: (names?.endDate || 'dropoffDate') as any,
    control,
    rules: { required: true },
  });
  const { field: startTimeField } = useController<T>({
    name: (names?.startTime || 'pickupTime') as any,
    control,
  });
  const { field: endTimeField } = useController<T>({
    name: (names?.endTime || 'dropoffTime') as any,
    control,
  });

  return (
    <DateTimeRangePicker
      {...props}
      displayFormat={displayFormat || `M/D/YY [-] hh:mm A [${timezoneAbbr}]`}
      endDate={endDateField.value}
      endTime={endTimeField.value}
      maxHours={props.maxHours}
      onEndDateChange={d => {
        onEndDateChangeCb?.(d);
        endDateField.onChange(d);
      }}
      onEndTimeChange={t => {
        onEndTimeChangeCb?.(t);
        endTimeField.onChange(t);
      }}
      onStartDateChange={d => {
        onStartDateChangeCb?.(d);
        startDateField.onChange(d);
      }}
      onStartTimeChange={t => {
        onStartTimeChangeCb?.(t);
        startTimeField.onChange(t);
      }}
      startDate={startDateField.value}
      startTime={startTimeField.value}
    />
  );
};
