import { makeStyles } from '@material-ui/core';

export const useSearchStyles = makeStyles(theme => ({
  form: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: theme.spacing(4),
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  vehicleContainer: {
    lineHeight: 1.6, // match webflow
    marginTop: '8px', // fix in webflow
  },
  locationContainer: {
    lineHeight: 1.6, // match webflow
    marginTop: '11px', // fix in webflow
    '& .MuiFormLabel-root': {
      color: theme.palette.black,
      fontSize: '14px',
      transform: 'none',
    },
  },
  searchContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  searchButtonContainer: {
    width: '100%',
  },
  mobileHelperContainer: {
    alignItems: 'center',
    display: 'flex',
    cursor: 'default',
    marginBottom: 0,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  desktopHelperContainer: {
    display: 'none',
    marginLeft: '16px',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
}));
