// TODO For some reason, this is not loading with css-loader correctly. Use the CDN version for now.
// import './react-datepicker.css';
import '../node_modules/@fluidtruck/core/dist/css/date-time-range-picker.css';

import {
  theme as TidalTheme,
  ThemeProvider as TidalThemeProvider,
} from '@fluidtruck/core';
import { ThemeProvider as MuiStylesProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import ReactDOM from 'react-dom';

import { Search } from './components';
import { muiTheme, theme } from './theme';

const App = () => (
  <TidalThemeProvider theme={TidalTheme}>
    <MuiStylesProvider theme={muiTheme}>
      <ThemeProvider theme={theme}>
        <Search />
      </ThemeProvider>
    </MuiStylesProvider>
  </TidalThemeProvider>
);

ReactDOM.render(
  React.createElement(App, {}, null),
  document.getElementById('fluid-inline-search'),
);
