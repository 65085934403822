import {
  Button as MuiButton,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'relative',
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        width: 134,
      },
    },
    button: {
      boxShadow: 'none',
      color: theme.palette.common.white,
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
      height: '100%',
      lineHeight: '18px',
      padding: theme.spacing(4.5, 8.5),
      textTransform: 'none',
      whiteSpace: 'nowrap',
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        marginTop: 0,
      },
      '&:hover, &:active': {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
        color: theme.palette.common.white,
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.primary.main,
        color: 'rgba(255, 255, 255, 0.5)',
      },
    },
    loading: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
      color: 'rgba(255, 255, 255, 0.5)',
    },
  }),
  { name: 'Button' },
);

export const Button = props => {
  const {
    children,
    disabled = false,
    loading = false,
    type = 'button',
    onClick,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.root} data-test-id={props['data-test-id']}>
      <MuiButton
        className={classes.button}
        color="primary"
        disabled={loading || disabled}
        onClick={onClick}
        type={type}
        variant="contained"
      >
        {!loading && children}
      </MuiButton>
      {loading && <CircularProgress className={classes.loading} size={24} />}
    </div>
  );
};
