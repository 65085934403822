import { LoadScript } from '@react-google-maps/api';
import React, { memo } from 'react';

import { SearchBar } from './SearchBar';

const libraries = ['places'];

export const Search = memo(() => (
  <LoadScript
    googleMapsApiKey="AIzaSyCGDh2-nBsNBdirAEk1PoQ16HRU5OXmChM"
    libraries={libraries}
  >
    <SearchBar />
  </LoadScript>
));
