import {
  ChakraStylesConfig,
  Link,
  OptionGroup,
  SingleValue,
  Text,
} from '@fluidtruck/core';
import { TextField, useTheme } from '@material-ui/core';
import { Autocomplete } from '@react-google-maps/api';
import moment from 'moment-timezone';
import qs from 'qs';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button } from '../Button';
import { DTRPController } from '../DTRPController';
import { ReactSelect } from '../ReactSelect';
import { useSearchStyles } from './styles';
import { vehicleTypes } from './utils';

type OptionType = { label: string; value: string };

interface FormData {
  dropoffDate: moment.Moment;
  dropoffTime: number;
  location: string;
  pickupDate: moment.Moment;
  pickupTime: number;
}

export const SearchBar = () => {
  const [loading, setLoading] = useState(false);
  const [
    autoComplete,
    setAutoComplete,
  ] = useState<google.maps.places.Autocomplete | null>(null);

  const styles = useSearchStyles();
  const theme = useTheme();

  const [type, setType] = useState<SingleValue<OptionGroup> | string>(
    vehicleTypes[0].value,
  );

  const someTZ = moment.tz.guess();

  const pickupDate = moment().tz(someTZ).add(2, 'hours').startOf('hour');
  const dropoffDate = moment().tz(someTZ).add(3, 'hours').startOf('hour');

  const { control, formState, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      pickupDate,
      pickupTime: pickupDate.hour(),
      dropoffDate,
      dropoffTime: dropoffDate.hour(),
    },
  });

  const onSubmit = (data: FormData) => {
    setLoading(true);

    const start = moment(data.pickupDate).hour(data.pickupTime).format();
    const end = moment(data.dropoffDate).hour(data.dropoffTime).format();

    const query = qs.stringify({
      start,
      end,
      type,
      location: data.location,
      radius: '7.6',
    });

    setLoading(false);
    window.location.href = `https://app.fluidtruck.com/search?${query}`;
  };

  const handleLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutoComplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autoComplete) {
      setValue('location', autoComplete.getPlace().formatted_address);
    }
  };

  const handleSetType = (value: SingleValue<OptionGroup>) => setType(value);

  const selectStyles: ChakraStylesConfig<OptionType, false> = {
    dropdownIndicator: provided => ({
      ...provided,
      '> svg': { height: 6, width: 6 },
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: 'md',
      lineHeight: 'shorter',
    }),
    option: provided => ({
      ...provided,
      maxWidth: 184,
    }),
    control: provided => ({
      ...provided,
      maxHeight: 8,
      minWidth: 184,
      maxWidth: '100%',
    }),
  };

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.vehicleContainer}>
          <ReactSelect
            chakraStyles={selectStyles}
            id="search-vehicle-type"
            label="Vehicle Type"
            onChange={handleSetType}
            options={vehicleTypes}
            placeholder="Vehicle Type"
            size="sm"
            value={vehicleTypes.find(({ value }) => value === type)}
          />
        </div>
        <div className={styles.locationContainer}>
          <Autocomplete onLoad={handleLoad} onPlaceChanged={onPlaceChanged}>
            <Controller
              fullWidth
              as={TextField}
              control={control}
              defaultValue=""
              InputLabelProps={{
                shrink: true,
                style: { color: theme.palette.common.black },
              }}
              inputProps={{ 'data-test-id': 'popover-input-location' }}
              label="Location"
              name="location"
              placeholder="Address, City, or Zip"
              rules={{ required: true }}
            />
          </Autocomplete>
        </div>
        <div>
          <DTRPController
            displayTime
            control={control}
            displayFormat={`M/D/YY [-] hh:mm A [${moment
              .tz(moment.tz.guess())
              .zoneAbbr()}]`}
            labels={{
              start: 'Pick Up',
              end: 'Drop Off',
            }}
            maxHours={8760}
            names={{
              startDate: 'pickupDate',
              startTime: 'pickupTime',
              endDate: 'dropoffDate',
              endTime: 'dropoffTime',
            }}
            timezoneAbbr={someTZ}
          />
        </div>

        <div className={styles.searchContainer}>
          <div className={styles.searchButtonContainer}>
            <Button
              data-test-id="popover-button-search"
              disabled={!formState.isValid}
              loading={loading}
              size="sm"
              type="submit"
            >
              Search
            </Button>
          </div>
          <div className={styles.mobileHelperContainer}>
            <Text fontSize={16} mb="0">
              Have questions?
              <Link
                fontSize={16}
                fontWeight={700}
                href="https://www.fluidtruck.com/forms"
                style={{ textDecoration: 'none', paddingLeft: 4 }}
                target="_blank"
                textColor="blue.500"
              >
                Get in touch
              </Link>
            </Text>
          </div>
        </div>
      </form>

      <div className={styles.desktopHelperContainer}>
        <Text display="inline" fontSize={16}>
          Interested in booking multiple vehicles or want to know more about our
          offerings?
        </Text>
        <Link
          fontSize={16}
          fontWeight={700}
          href="https://www.fluidtruck.com/forms"
          style={{ textDecoration: 'none', paddingLeft: 4, lineHeight: '24px' }}
          target="_blank"
          textColor="blue.500"
        >
          Get in touch
        </Link>
      </div>
    </>
  );
};
