import { createMuiTheme } from '@material-ui/core';

export const theme = {
  colors: {
    alertBox: '#DA0000',
    fluidBlue: '#00B2E3',
    black: '#2D333A',
    white: '#FFFFFF',
    error: '#F44336',
    lightGray: '#F2F2F2',
    componentGray: '#E5E5E5',
    keylineGray: '#CCCCCC',
    fluidDarkGray: '#A7B2BB',
    darkGray: '#666666',
    borderGray: '#C3D0D9',
    gray: '#949494',
    success: '#00AB61',
    expiredGray: '#CCCCCC',
    blueDisabled: '#94ddf3',
    secondaryGray: '#8f8f8f',
  },
  font: "'Nunito Sans', sans-serif",
};

export const muiTheme = createMuiTheme({
  breakpoints: {
    values: {
      sm: 500,
      md: 768,
      lg: 992,
      xl: 1440,
    },
  },
  palette: {
    common: {
      black: theme.colors.black,
      white: theme.colors.white,
    },
    primary: {
      main: theme.colors.fluidBlue,
    },
  },
  shape: {
    borderRadius: 10,
  },
  spacing: 4,
  typography: {
    fontFamily: theme.font,
    fontSize: 16,
    lineHeight: '16px',
  },
});
