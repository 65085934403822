import {
  Box,
  ChakraReactSelect,
  ChakraReactSelectProps as Props,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GroupBase,
  isString,
  OptionGroup,
  SystemStyleObject,
} from '@fluidtruck/core';
import { useTheme } from '@material-ui/core';
import React, { ReactElement } from 'react';

/**
   * @description ChakraReactSelect
   * https://tidal.fluidtruck.com/docs/form/chakra-react-select
   * Important: For Single Values
   * @example
      const options = [{ value: 'red', label: 'RED' }, ....]
  
      <MonthlySelect
        defaultValue={options[0].value}
        options={options}
        placeholder="Choose a color"
        onChange={handleSelectOnChange}
      />
   */

export type CRSProps = {
  noLabel?: boolean;
  label?: string;
  errorMessage?: string;
  'data-test-id'?: string;
  id?: string;
  sx?: SystemStyleObject;
  variant?: string;
} & Props<OptionGroup, false, GroupBase<OptionGroup>>;

export const ReactSelect = ({
  options = [],
  noLabel,
  label,
  value: valueProp,
  isInvalid,
  'data-test-id': testId,
  onChange: onChangeProp,
  errorMessage,
  onBlur: onBlurProp,
  isSearchable = false,
  sx,
  ...rest
}: CRSProps): ReactElement => {
  const theme = useTheme();

  const filteredValue = options.find(opt => {
    // @ts-ignore
    if ('value' in opt) return opt?.value === valueProp;
    return opt || {};
  });

  const input = (option?: any) => {
    return isString(option) ? filteredValue : option;
  };

  const output = (option: any) => (isString(option) ? option : option.value);

  const handleChange = (newValue: any, actionMeta: any) => {
    onChangeProp?.(output(newValue), actionMeta.action);
  };

  return (
    <Box as={FormControl} data-test-id={testId} isInvalid={isInvalid} sx={sx}>
      {!noLabel && (
        <FormLabel style={{ fontSize: 14, color: theme.palette.common.black }}>
          {label}
        </FormLabel>
      )}
      <ChakraReactSelect
        onBlur={onBlurProp}
        options={options}
        {...rest}
        isSearchable={isSearchable}
        onChange={handleChange}
        placeholder="Search..."
        value={input(valueProp)}
      />
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </Box>
  );
};
